let Environment = {
  // CreaterAddress: "0x6ab3beac4f5f0fE1c6f0CE38d20964225Be68A31",
  // for mainnet
  LaunchpadContract: "0x6B3188AC879b3ba14D79e76068f22E2E99598CF0",
  // MarketAddress: "0x9c5F504457C3d7D073AfaD3248F96681c890F43b",

  // for testnet
  // CreaterAddress: "0x502805D3bC518E06B99F4bbE81700cfa3C252cB0",
  // MarketAddress: "0x7d9d57DBaea57ec0040f1AB2F42e402b4DB3679D",
};
export default Environment;
